@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: 'Raleway', 'Roboto', 'Oxygen', 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

